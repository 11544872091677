import { useState } from "react";
import { Skills } from "./Skills";
import { Projects } from "./Projects";

export const SkillsProject = () => {
  const [selectedSkill, setSelectedSkill] = useState(null);

  return (
    <div>
      <Skills selectedSkill={selectedSkill} onSelectSkill={setSelectedSkill} />
      <Projects selectedSkill={selectedSkill} />
    </div>
  );
};
