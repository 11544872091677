import { Container, Row, Col, Tab } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";

// formation images
import NSI from "../assets/img/project/NSI.png";
import SDV from "../assets/img/project/SDV.webp";
import python1 from "../assets/img/project/python1.jpeg";
import python2 from "../assets/img/project/python2.png";
import SQL1 from "../assets/img/project/SQL1.webp";
import alta1 from "../assets/img/project/alta.png";
import dictateur from "../assets/img/project/dictateur.png";
import docu from "../assets/img/project/docu.jpg";
import fivem from "../assets/img/project/fivem.jpg";
import locauto from "../assets/img/project/locauto.png";



import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = ({ selectedSkill }) => {
  const projects = [
    { 
      title: "Projet Bot Python", 
      description: "Projet d’un bot Python conçu pour comparer les prix des annonces de voitures d’occasion en Allemagne avec ceux en France, afin de simplifier la recherche des meilleures offres.", 
      imgUrl: python1, 
      skills: ["Python"], 
      githubLink: "https://github.com/monrepo/bot-python" // Lien GitHub
    },

    { 
      title: "Création d'une base de donée Mysql avec PhpMyAdmin", 
      description: "Création de la base de donnée pour le projet d'un mode de jeu sur GTA5 RP", 
      imgUrl: SQL1, 
      skills: ["Gestion de base de donnée"], 
      githubLink: "https://github.com/monrepo/bot-python" // Lien GitHub
    },

    { 
      title: "Création d'une base de donée Mysql avec PhpMyAdmin", 
      description: "Création de la base de donnée d'un site php avec espace utilisateur et admin", 
      imgUrl: SQL1, 
      skills: ["Gestion de base de donnée"], 
      githubLink: "https://github.com/monrepo/bot-python" // Lien GitHub
    },
    { 
      title: "Création d'une maquette de site web php en HTML/CSS/JS", 
      description: "", 
      imgUrl: alta1, 
      skills: ["HTML/CSS/JS"], 
      githubLink: "https://github.com/Remisdv/altawl/tree/main" // Lien GitHub
    },

    { 
      title: "Création d'un site web maquette en HTML/CSS", 
      description: "", 
      imgUrl: dictateur, 
      skills: ["HTML/CSS/JS"], 
      githubLink: "https://github.com/Remisdv/altawl/tree/main" // Lien GitHub
    },

    { 
      title: "Création d'une multitude de script", 
      description: "Création d'une multitude de script pour des serveurs de jeux sur FiveM", 
      imgUrl: fivem, 
      skills: ["Lua"], 
      githubLink: "https://github.com/Remisdv/GTA-SCRIPT" // Lien GitHub
    },

    { 
      title: "Création d'un site web en PHP", 
      description: "Création d'un site web en PHP avec espace utilisateur et admin", 
      imgUrl: alta1, 
      skills: ["PHP"], 
      githubLink: "https://github.com/Remisdv/ALTAWL-WEB" // Lien GitHub
    },

    { 
      title: "Création d'un site web en PHP", 
      description: "Projet Locauto avec SupdeVinci", 
      imgUrl: locauto, 
      skills: ["PHP"], 
      githubLink: "https://github.com/Remisdv/Locauto" // Lien GitHub
    },


  ];



  const formations = [
    { title: "Formation Python", description: "Spécialité NSI au lycée André Maurois (2021/2023)", imgUrl: NSI, skills: ["Python"] },
    { title: "Formation Python avancé", description: "Formation Python avancée avec le formateur Benard Valton a Sup De Vinci (2023 / 2024)", imgUrl: SDV, skills: ["Python"] },
    { title: "Formation PHP", description: "Formation Php avec le formateur Benard Valton a Sup de Vinci (2024)", imgUrl: SDV, skills: ["PHP"] },
    { title: "Formation SQL", description: "Formation au SQL en spécialité NSI au lycée André Maurois (2022/2023)", imgUrl: NSI, skills: ["Gestion de base de donnée"] },
    { title: "Formation SQL / MCD", description: "Formation Postgresql et au MCD a Sup De Vinci avec le formateur Benard Valton (2023/2024)", imgUrl: SDV, skills: ["Gestion de base de donnée"] },
    { title: "Formation HTML/CSS", description: "Formation HTML / CSS débutant", imgUrl: NSI, skills: ["HTML/CSS/JS"] },
    { title: "Formation HTML/CSS/JS", description: "Formation HTML / CSS avancé et formation JavaScript", imgUrl: SDV, skills: ["HTML/CSS/JS"] },
    { title: "Formation Lua", description: "Formation via des documentations et des développeur ", imgUrl: docu, skills: ["Lua"] },


  ];

  const filteredProjects = selectedSkill
    ? projects.filter(project => project.skills.includes(selectedSkill))
    : projects;

  const filteredFormations = selectedSkill
    ? formations.filter(formation => formation.skills.includes(selectedSkill))
    : formations;

  const allProjects = selectedSkill ? filteredProjects : [...projects];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div>
                <h2>{selectedSkill || "Tous"}</h2>
                <p>Découvrez quelques projets que j'ai réalisés, mettant en avant mes compétences en développement et en design.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__pulse" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        <h2>Formations</h2>
                        {selectedSkill ? filteredFormations.map((formation, index) => (
                          <ProjectCard
                            key={index}
                            {...formation}
                          />
                        ))
                         : formations.map((formation, index) => (
                          <ProjectCard
                            key={index}
                            {...formation}
                          />
                        ))}
                      </Row>
                      <Row>
                        <h2>Projets</h2>
                        {allProjects.map((project, index) => (
                          <ProjectCard
                            key={index}
                            {...project}
                          />
                        ))}
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="Background"/>
    </section>
  );
};
