import { Col, Button } from "react-bootstrap";

export const ProjectCard = ({ title, description, imgUrl, githubLink }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} alt={title} />
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
          {githubLink && (
            <Button 
            style={{
              backgroundColor: "#fff",
              color: "#121212",
              borderRadius: "20px",
              marginTop: "10px",
              fontWeight: "500",
              fontSize: "16px",
              border: "2px solid #fff",
              transition: "0.3s ease-in-out"
            }}
            href={githubLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Voir sur GitHub
          </Button>
          )}


          
        </div>
      </div>
    </Col>
  );
};
